import { FeatureCarouselItem } from './FeatureCarousel';
import { RewardsAvailability } from './RewardsAvailability';
import { Terms } from './Terms';

export enum RemoteConfigKey {
  should_force_app_update = 'should_force_app_update',
  carousel_items = 'carousel_items',
  enable_qr_code_checkin = 'enable_qr_code_checkin',
  terms = 'terms',
  rewards_availability = 'rewards_availability',
}

export interface RemoteConfigValues {
  [RemoteConfigKey.should_force_app_update]: boolean;
  [RemoteConfigKey.carousel_items]: FeatureCarouselItem[];
  [RemoteConfigKey.enable_qr_code_checkin]: Record<string, boolean>;
  [RemoteConfigKey.terms]: Terms;
  [RemoteConfigKey.rewards_availability]: RewardsAvailability;
}
