import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RewardsState {
  areFoodCardsAvailable?: boolean;
}

const initialState: RewardsState = {};

const rewardsSlice = createSlice({
  name: 'rewards',
  initialState: initialState,
  reducers: {
    setFoodCardsAvailability: (state, action: PayloadAction<boolean>) => {
      state.areFoodCardsAvailable = action.payload;
    },
  },
});

export const { setFoodCardsAvailability } = rewardsSlice.actions;
export default rewardsSlice.reducer;
